import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Spinner,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
// import MiniStatistics from "components/card/MiniStatistics";
// import IconBox from "components/icons/IconBox";
import React from "react";
// import {
//   MdAddTask,
//   MdAttachMoney,
//   MdBarChart,
//   MdFileCopy,
// } from "react-icons/md";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import DashboardEmployeeTable from "views/admin/dataTables/components/DashboardEmployeeTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataComplex,
  dashboardCityColumns,
  dashboardEmployeeColumns
} from "views/admin/default/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useHistory } from 'react-router-dom';
import { getCities, getAllEmployees } from "services/admin";
import { useUserStore } from '../../../stores/userStore';
import { useCityStore } from "stores/cityStore";
import { useBranchStore } from "stores/branchStore";
import webSocketService from "sockets";
export default function UserReports() {
  const token = localStorage.getItem('token');
  let history = useHistory();
  if (!window.reactHistory) {
    window.reactHistory = history;
    // attach history object to window object. 
  }
  // const [citiesData, setCitiesData] = React.useState(null);
  const updateCity = useCityStore(store => store.setCity)
  const updateBranch = useBranchStore(store => store.setBranch)

  const [employeeData, setEmployeeData] = React.useState(null);
  const user = useUserStore((state) => state.user);
  const cityData = useCityStore((state) => state.city)

  const handleNotification = (a) => {
    console.log(a);
  }

  React.useEffect(() => {
    if (!token) {
      history.push('/auth/sign-in');
    } else {
      webSocketService.connect(handleNotification);
      switch (user.role) {
        case "ADMIN":
          fetchAdminDashboard();
          break;
        case "BRANCH_MANAGER":
          fetchBranchManagerDashboard();
          break;
        case "EMPLOYEE":
          fetchEmployeeDashboard();
          break;
        case "HR":
          fetchAdminDashboard();
      }
    }
  }, [])
  const fetchAdminDashboard = () => {
    fetchCities();
  }
  const fetchBranchManagerDashboard = () => {
  }
  const fetchEmployeeDashboard = () => {
  }

  // React.useEffect(() => {
  //   if(user.role == 'ADMIN' || user.role == 'HR')fetchEmployees();
  // }, [cityData])
  const fetchEmployees = async () => {
    let branchIds = [];
    let branches = [];
    let params = {};
    if (cityData.cities) {
      cityData.cities.map(city => {
        city.branches.map(branch => {
          branches.push({name:branch.name,id:branch.id})
          branchIds.push(branch.id);
        })
      })
      params.branchIds = branchIds;
      params.userStatus = ["ACTIVE", "PASSIVE"];
      updateBranch(branches);
      try {
        const response = await getAllEmployees();
        setEmployeeData(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  }
  const fetchCities = async () => {
    try {
      let response = await getCities();
      updateCity({cities:response.data.sort((a,b) => a.name.localeCompare(b.name))})
      fetchEmployees();
      // setCitiesData(response.data);
    }
    catch (err) {
      console.error(err);
    }
  }
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Earnings'
          value='$350.4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Spend this month'
          value='$642.39'
        />
        <MiniStatistics growth='+23%' name='Sales' value='$574.34' />
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        />
      </SimpleGrid> */}

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        {cityData.cities && (user.role == 'ADMIN' || user.role == 'HR')  &&  <ColumnsTable columnsData={dashboardCityColumns} tableData={cityData.cities} />}
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
        {!employeeData ? <Flex justify="center" align="center" height="-webkit-fill-available">
          <Spinner width='120px' height='120px' />
        </Flex> : <DashboardEmployeeTable columnsData={dashboardEmployeeColumns} tableData={employeeData} /> }
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid> */}
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
