import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  useColorModeValue,
  Flex,
  Button,
  Select,
  FormLabel,
  Box,
  Input,
  Text,
  Tab, TabList, TabPanel, TabPanels, Tabs
} from "@chakra-ui/react";
import { getShifts, createShift , deleteShift } from "services/shift";
import { getEmployees } from "services/branchManager";
import allLocales from "@fullcalendar/core/locales-all";
import { useUserStore } from "stores/userStore";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { dayNames } from "utils/utils";
import { monthNames } from "utils/utils";
import { Toaster } from 'react-hot-toast';
import ShiftTable from "./shiftTable";

import "./shiftManagement.css";
// import ShiftManagementComponent from './test.jsx';
// import './ShiftManagementComponent.css';

const validationSchema = Yup.object({
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
});

const ShiftManagement = () => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const [shifts, setShifts] = React.useState([]);
  const [shiftsData, setShiftsData] = React.useState([]);
  const [employees, setEmployees] = React.useState(null);
  const [activeEmployee, setSelectedEmployee] = React.useState("");
  const user = useUserStore((state) => state.user);

  const fetchEmployeeList = async () => {
    let response = await getEmployees();
    if (response.status == 200) {
      setEmployees(response.data);
      setSelectedEmployee(response.data[0]);
    }
  };
  const fetchShifts = async () => {
    let response = await getShifts();
    if (response.status == 200) {
      let mappedShifts = extractShiftsData(response.data);
      setShifts(mappedShifts);
    }
  };
  function getRandomColor() {
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  function extractShiftsData(shiftsArray) {
    let newShiftsArray = [];

    shiftsArray.forEach((day) => {
      day.shifts.forEach((shift) => {
        let shiftData = {
          id:shift.id,
          title:
            shift.userName + "(" + shift.startTime + "-" + shift.endTime + ")" + ' ❌',
          date: day.date,
          color: getRandomColor(),
        };
        newShiftsArray.push(shiftData);
      });
    });

    return newShiftsArray;
  }

  React.useEffect(() => {
    if (user.role != "ADMIN") {
      fetchEmployeeList();
      fetchShifts();
    }
  }, []);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  function createDateRangeArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Convert dates to string in 'YYYY-MM-DD' format
    return dateArray.map(date => {
      return formatDate(date);
    });
  }
  const saveShift = async (values) => {
    let dateRanges = createDateRangeArray(dateStart,dateEnd)
    const transformedArray = dateRanges.map(date => ({
      date: date,
      startTime: values.startTime,
      endTime: values.endTime
    }));
    let params = {
      employeeId: activeEmployee.id,
      shifts: transformedArray,
    };
    try {
      let response = await createShift(params);
      console.log(response);
    } catch (e) {
      console.log(e);
    }finally{
      fetchShifts();
    }
  };

  function onChangeHandler(e) {
    let selectedEmployee = employees.find(
      (emp) => emp.id == Number(e.target.value)
    );
    setSelectedEmployee(selectedEmployee);
  }
 
  const handleEventClick = (clickInfo) => {
    console.log(clickInfo.event.id,"id");
    if (
      window.confirm(
        `Silmek İstediğinize Emin Misiniz ? '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
      deleteShift(clickInfo.event.id);
    }
  };
  const [dateStart, setDateStart] = React.useState(new Date());
  const [dateEnd, setDateEnd] = React.useState(new Date());
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };



  const BranchManagerUI = () => (
    <>
      <Select
        variant="filled"
        m={0}
        h="44px"
        maxH="44px"
        name="employees"
        value={activeEmployee?.id || ""}
        onChange={(e) => onChangeHandler(e)}
        width="250px"
      >
        {employees &&
          employees.length > 0 &&
          employees.map((employee) => (
            <option value={employee.id} key={employee.id}>
              {employee.name}
            </option>
          ))}
      </Select>
      <Flex flexDirection={'column'} textAlign='center'>
      <Text>Başlangıç Tarihi</Text>
      <SingleDatepicker
        configs={{
          dateFormat: "yyyy-MM-dd",
          dayNames: dayNames, // Turkish day names
          monthNames: monthNames, // Turkish month names
          firstDayOfWeek: 1, // Monday is the first day of the week
        }}
        propsConfigs={{
          inputProps: {
            width: "250px",
            marginLeft: "8px",
            color: "white",
            borderColor: "white",
          },
        }}
        date={dateStart}
        onDateChange={setDateStart}
      />
      </Flex>
      <Flex flexDirection={'column'} textAlign='center'>
      <Text>Bitiş Tarihi</Text>
      <SingleDatepicker
        configs={{
          dateFormat: "yyyy-MM-dd",
          dayNames: dayNames, // Turkish day names
          monthNames: monthNames, // Turkish month names
          firstDayOfWeek: 1, // Monday is the first day of the week
        }}
        propsConfigs={{
          inputProps: {
            width: "250px",
            marginLeft: "8px",
            color: "white",
            borderColor: "white",
          },
        }}
        date={dateEnd}
        onDateChange={setDateEnd}
      />
      </Flex>
      <Formik
        initialValues={{
          startTime: "10:00",
          endTime: "18:00",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveShift(values);
        }}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              alignItems: "end",
              columnGap: "8px",
              marginLeft: "8px",
            }}
          >
            <Box>
              <FormLabel padding={"8px"}>Başlangıç Saati</FormLabel>
              <Field
                name="startTime"
                as={Input}
                type="time"
                value={values.startTime}
                onChange={handleChange}
                onBlur={handleBlur}
                background="white"
                color="black"
              />
            </Box>
            <Box>
              <FormLabel padding={"8px"}>Bitiş Saati</FormLabel>
              {/* <ErrorMessage
                render={(msg) => (
                  <Box paddingLeft={"8px"} color="red">
                    {msg}
                  </Box>
                )}
                name="endTime"
                component="div"
              /> */}
              <Field
                name="endTime"
                as={Input}
                type="time"
                value={values.endTime}
                onChange={handleChange}
                onBlur={handleBlur}
                background="white"
                color="black"
              />
            </Box>

            <Button type="submit">Kaydet</Button>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <div className="shift-management-container" style={{ marginTop: "70px" }}>
    <Tabs index={tabIndex} onChange={handleTabChange}>
      <TabList>
        <Tab>{user.role === "BRANCH_MANAGER" && "Shift Takvimi"}</Tab>
        <Tab>{user.role === "BRANCH_MANAGER" && "Shift Tablosu"}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>      
            <Flex alignItems="end" wrap='wrap' mt={4} w="100%" justifyContent='space-between' marginBottom='36px'>
            {user.role === "BRANCH_MANAGER" && <BranchManagerUI />}
            </Flex>
            <FullCalendar
                startParam="today"
                locales={allLocales}
                locale={"tr"}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next",
                  center: "title",
                  right: "dayGridWeek,dayGridDay,dayGridMonth",
                }}
                events={shifts}
                selectable={true}
                eventClick={handleEventClick}
              />
        </TabPanel>
        <TabPanel>
            <ShiftTable />
        </TabPanel>
      </TabPanels>
    </Tabs>
      <Toaster  position="top-right" />
    </div>
  );
};

export default ShiftManagement;
