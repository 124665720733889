export const titleEnum = {
  BRANCH_MANAGER: 'Şube Müdürü',
  ADMIN: 'Yönetici',
  EMPLOYEE: 'Çalışan',
  FINANCE: 'Finans',
  WAREHOUSE_MANAGER:'Depo Müdürü',
  HR:'İnsan Kaynakları',
  TECHNIC:'Teknik Eleman',
  GRAPHIC:'Grafiker'
}
export const roleEnum = [
  {
    value:"ADMIN",
    name:"Admin"
  },
  {
    value:"BRANCH_MANAGER",
    name:"Şube Müdürü"
  },
  {
    value:"EMPLOYEE",
    name:"Çalışan"
  },
  {
    value:"FINANCE",
    name:"Finans"
  },
  {
    value:"WAREHOUSE_MANAGER",
    name:"Depo Müdürü"
  },
  {
    value:"HR",
    name:"İnsan Kaynakları"
  },
  {
    value:"TECHNIC",
    name:"Teknik Eleman"
  }
]
export const statusEnum = {
  ACTIVE: 'Aktif',
  PASSIVE: 'Pasif',
  PASSWORD_CHANGE_REQUIRED: 'Şifre Blokeli'
}

export const typeEnum = {
  PRODUCT: 'Ürün',
  RAW: "Hammadde"
}
export const subTypeEnum = {
  PRODUCT: 'Ürün',
  RAW: "Hammadde"
}

export const taskStatusEnum = {
  CREATED : 'Oluşturuldu',
  ONGOING : 'Devam Ediyor',
  COMPLETED: 'Tamamlandı',
  CANCELED: 'İptal Edildi'
}
export const annualEnum ={
  CREATED : 'Oluşturuldu',
  ACCEPTED : 'Kabul Edildi',
  DECLINED: 'Reddedildi',

}