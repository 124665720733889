import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Select,
  useColorModeValue,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box
} from "@chakra-ui/react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
//filepond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Custom components
import Card from "components/card/Card";
import Pagination from "components/Pagination";
import InputField from "components/fields/InputField";
import TextAreaField from "components/fields/TextAreaField";
import toast, { Toaster } from "react-hot-toast";
import { MultiSelect } from "chakra-multiselect";
import "../../../../assets/css/multiselect.css";
import { useHistory } from "react-router-dom";

import { taskStatusEnum } from "enums";

import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";
import { format } from "date-fns";

import { createTask } from "services/admin";
import { useTaskStore } from "stores/taskStore";
import { useUserStore } from "stores/userStore";
import "../../../../assets/css/taskManagement.css";
import { dayNames } from "utils/utils";
import { monthNames } from "utils/utils";

// Assets

export default function EmployeeTable(props) {
  const { columnsData, tableData, fetchTask, employeeData } = props;
  let history = useHistory();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  const openModal1 = () => setIsOpenModal1(true);
  const closeModal1 = () => setIsOpenModal1(false);

  const openModal2 = () => setIsOpenModal2(true);
  const closeModal2 = () => setIsOpenModal2(false);

  const [statusValue, setStatusValue] = useState([]);

  const [employeeValue, setEmployeeValue] = useState([]);
  const [employeeValueId, setEmployeeValueId] = useState([]);

  const [filterPersonelId, setFilterPersonelId] = useState("Seçiniz");

  const [statusOptions, setStatusOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const [descriptonVal, setDescriptonVal] = useState("");
  const [titleVal, setTitleVal] = useState("");
  const [files, setFiles] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadFileErr,setUploadFileErr] = useState(false)

  const statusColors = {
    CREATED: 'blue',
    ONGOING: 'yellow',
    COMPLETED: 'green',
    CANCELED: 'red'
  };

  const user = useUserStore((state) => state.user);
  // date picker
  const aMonthAgo = new Date();
  const aMonthLater = new Date();
  aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
  aMonthLater.setMonth(aMonthLater.getMonth() + 1);
  const [selectedDates, setSelectedDates] = useState([aMonthAgo, aMonthLater]);

  const [dueDate, setDueDate] = useState(new Date());
  const updateTask = useTaskStore((store) => store.setTask);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const status = {
    Oluşturuldu: "CREATED",
    "Devam Ediyor": "ONGOING",
    Tamamlandı: "COMPLETED",
    "İptal Edildi": "CANCELED",
  };

  function handleImageUpload(fileitems,err) {
    if(err){
      console.log(err);
    }
    const updatedFiles = fileitems.map((fileItem) => fileItem.file);
    const fileByte = updatedFiles.reduce((total,item)=>(total + item.size),0)
    if(fileByte >= 104857600){
      setUploadFileErr(true)
    }else{
      setUploadFileErr(false)
    }
    const files = new DataTransfer();
    updatedFiles.forEach((file) => {
      files.items.add(file);
    });
    const fileList = files.files;
   
    setFile(updatedFiles); // name
    setFiles(fileList); // upload
  }

  useEffect(()=>{
    if(uploadFileErr){
      toast.error('Toplam Dosya Boyutu 100MB den Fazla Olamaz')
    }
  },[uploadFileErr])
  useEffect(() => {
    const newlyCapturedIds = employeeValue.map((isim) => {
      const findID = employeeData.find((data) => data.name === isim);
      return findID ? findID.id : null;
    });
    setEmployeeValueId(newlyCapturedIds);
  }, [employeeValue]);

  console.log(employeeValueId);
  useEffect(() => {
    let keys = Object.values(status);
    setStatusOptions(
      keys.map((stat) => ({
        label: taskStatusEnum[stat],
        value: taskStatusEnum[stat],
      }))
    );
  }, []);

  useEffect(() => {
    if (employeeData) {
      setEmployeeOptions(
        employeeData.map((emp) => ({
          label: emp.name,
          value: emp.name,
        }))
      );
    }
  }, [employeeData]);

  const submitForm = async () => {
    
    if (descriptonVal != "" && titleVal != "" && employeeValueId.length > 0 && !uploadFileErr) {
      try {
        let response = await createTask({
          description: descriptonVal,
          assigneeIdList: employeeValueId,
          dueDate: format(dueDate, "yyyy-MM-dd"),
          title: titleVal,
          files: files,
        });
      
        if (response.status == 200) {
          toast.success("Görev başarı ile atandı");

          fetchTask({
            startDate: format(aMonthAgo, "yyyy-MM-dd"),
            endDate: format(aMonthLater, "yyyy-MM-dd"),
          });

          setDescriptonVal("");
          setEmployeeValue([]);
          setFiles(null);
          setFile(null);
          setDueDate(new Date());
          setTitleVal("");
          setUploadFileErr(false);
          closeModal2();
        }else if (response.response.data.code == 100001){
          toast.error("Desteklenmeyen Dosya Türü");
        }
      } catch (err) {
        toast.error(err);
      }
    } else {
      toast.error("Tanım, Başlık, Personel Alanları Boş Bırakılamaz ve Toplam Dosya Boyutu 100MB den Fazla Olamaz");
    }
  };
  //filepond
  registerPlugin(FilePondPluginImagePreview,FilePondPluginFileValidateSize);
  const filterTask = async () => {
    if (statusValue.length == 0) {
      let response = await fetchTask({
        startDate: format(selectedDates[0], "yyyy-MM-dd"),
        endDate: format(selectedDates[1], "yyyy-MM-dd"),
        userId: filterPersonelId == "Seçiniz" ? "" : filterPersonelId,
      });
    } else {
      let value = statusValue.map((stat) => status[stat]);
      let response = await fetchTask({
        statusList: [value],
        startDate: format(selectedDates[0], "yyyy-MM-dd"),
        endDate: format(selectedDates[1], "yyyy-MM-dd"),
        userId: filterPersonelId == "Seçiniz" ? "" : filterPersonelId,
      });
    }

    closeModal1();
  };
  const resetFilter = async () => {
    await fetchTask({
      statusList: ["CREATED", "COMPLETED", "CANCELED", "ONGOING"],
      startDate: format(aMonthAgo, "yyyy-MM-dd"),
      endDate: format(aMonthLater, "yyyy-MM-dd"),
    });
    setStatusValue([]);
    setSelectedDates([aMonthAgo, aMonthLater]);
    setFilterPersonelId("Seçiniz");
  };
  const handleRowClick = (data) => {
    history.push(`/admin/task-detail/${data}`);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("gray.500", "whiteAlpha.900");
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Görev Tablosu
          </Text>
          <Flex>
            {(user.role == "ADMIN" ||user.role == "FINANCE" ||user.role == "WAREHOUSE_MANAGER" || user.role == "HR" || user.role == "TECHNIC")    && (
              <Button onClick={() => openModal2()}>Görev Ekle</Button>
            )}
            <Button marginLeft="12px" onClick={() => openModal1()}>
              Filtrele
            </Button>
          </Flex>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      textTransform="capitalize"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaCaretDown />
                        ) : (
                          <FaCaretUp />
                        )
                      ) : (
                        // Render a neutral icon if not sorted
                        <FaCaretUp />
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr
                  cursor="pointer"
                  {...row.getRowProps()}
                  key={index}
                  onClick={() => handleRowClick(row.original.id)}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                    if (cell.column.Header === "Detay") {
                      data = (
                        <Flex align="center">
                          {cell.value && (
                            <Button
                              w="80px"
                              minW="80px"
                              variant="brand"
                              fontWeight="500"
                              onClick={() => console.log(row.original)}
                            >
                              Düzenle
                            </Button>
                          )}
                        </Flex>
                      );
                    }
                    else if (cell.column.Header == 'Atanan'){
                      data = (
                        <Flex>
                        <Box h='24px' w='4px' bg={statusColors[cell.row.values.status]}></Box>
                        <Text marginLeft='8px' color={textColor} fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        </Flex>
                      )
                    } 
                    else if (cell.column.Header === "Durum") {
                      data = (
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {taskStatusEnum[cell.value]}
                          </Text>
                        </Flex>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Toaster position="top-right" />
        <Pagination
          variant='sm'
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      <Modal
        isOpen={isOpenModal1}
        onClose={closeModal1}
        isCentered
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader bg={borderColor}>{userData.name ? userData.name + 'Düzenle' : 'Çalışan Ekle'}</ModalHeader> */}
          <ModalHeader bg={borderColor}>Filtrele</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg={borderColor}>
            <Flex
              px="25px"
              justify="space-between"
              mb="20px"
              direction="column"
              align="start"
              gap="10px"
            >
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Filtrele
              </Text>
              <Text>Personele Göre</Text>
              <Select
                mb={2}
                required
                name="cityName"
                value={filterPersonelId}
                onChange={(e) => setFilterPersonelId(e.target.value)}
              >
                <option disabled>Seçiniz</option>
                {employeeData &&
                  employeeData.map((emp, index) => (
                    <option key={index} value={emp.id}>
                      {emp.name}
                    </option>
                  ))}
              </Select>

              <div className="multi-select-wrapper">
                <MultiSelect
                  width="350px"
                  borderColor={textColor}
                  listProps={{
                    width: "100%",
                    background: bgColor,
                    zIndex: "10",
                    padding: "16px",
                    borderRadius: "8px",
                    textColor: "black",
                  }}
                  id="branch-multiselect"
                  options={statusOptions}
                  value={statusValue}
                  label="Görev Durumuna Göre"
                  actionGroupProps={{
                    toggleButtonProps: {
                      display: "none",
                    },
                  }}
                  onChange={setStatusValue}
                />
              </div>
              <Text>Tarihe Göre</Text>
              <RangeDatepicker
                configs={{
                  dateFormat: "yyyy-MM-dd",
                  dayNames: dayNames, // Turkish day names
                  monthNames: monthNames, // Turkish month names
                  firstDayOfWeek: 1, // Monday is the first day of the week
                }}
                propsConfigs={{
                  inputProps: {
                    color: textColor,
                    borderColor: textColor,
                  },
                }}
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
              />
            </Flex>
          </ModalBody>
          <ModalFooter bg={borderColor}>
            <Button colorScheme="green" mr={3} onClick={filterTask}>
              Filtrele
            </Button>
            <Button colorScheme="red" mr={3} onClick={resetFilter}>
              Sıfırla
            </Button>
            <Button colorScheme="blue" mr={3} onClick={closeModal1}>
              Kapat
            </Button>
            {/* Add other modal actions as needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        scrollBehavior="inside"
        size="90%"
        padding="20px"
        display="block"
        isOpen={isOpenModal2}
        onClose={closeModal2}
        isCentered
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <ModalOverlay />
        <ModalContent w={"90%"}>
          <ModalHeader bg={borderColor}>Görev Ekle</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg={borderColor}>
            <InputField
              labelmargin={"12px"}
              maxLength={50}
              background="transparent"
              border={`1px solid ${textColor}`}
              color={textColor}
              mb="0"
              required
              name="title"
              label="Görev Başlığı"
              value={titleVal}
              onChange={(e) => setTitleVal(e.target.value)}
              maxLengthWarnText="Maksimum 50 karakter."
            />
            <TextAreaField
              rows="4"
              labelmargin={"12px"}
              maxLength={500}
              background="transparent"
              border={`1px solid ${textColor}`}
              color={textColor}
              mb="0"
              required
              name="description"
              label="Görev Açıklaması"
              value={descriptonVal}
              onChange={(e) => setDescriptonVal(e.target.value)}
              maxLengthWarnText="Maksimum 500 karakter."
            />
            <FormLabel
              style={{
                marginTop: "12px",
                fontSize: "0.875rem",
                fontWeight: "bold",
                margin: "10px",
              }}
            >
              Teslim Tarihi
            </FormLabel>
            <SingleDatepicker
              configs={{
                dateFormat: "yyyy-MM-dd",
                dayNames: dayNames, // Turkish day names
                monthNames: monthNames, // Turkish month names
                firstDayOfWeek: 1, // Monday is the first day of the week
              }}
              propsConfigs={{
                inputProps: {
                  color: textColor,
                  borderColor: textColor,
                },
              }}
              name="date-input"
              date={dueDate}
              onDateChange={setDueDate}
            />
            <Box marginTop={"12px"}>
              <MultiSelect
                width="100%"
                borderColor={textColor}
                listProps={{
                  background: bgColor,
                  zIndex: "10",
                  padding: "16px",
                  borderRadius: "8px",
                  textColor: "black",
                  w: "80%",
                }}
                placement="top"
                options={employeeOptions}
                value={employeeValue}
                labelProps={{
                  marginTop: "12px",
                  fontSize: "sm",
                  fontWeight: "bold",
                  ms: "10px",
                  outline:'none',

                  
                }}
                label="Görev Atanacak Personel"
                onChange={setEmployeeValue}
              />
            </Box>
            {/* <InputField
              lang="tr"
              multiple
              onChange={(e) => setFiles(e.target.files)}
              id="file-input"
              type="file"
              background="transparent"
              border={`1px solid ${textColor}`}
              color={textColor}
              mb="0"
              required
              name="files"
            /> */}
            <FilePond
              files={file}
              maxTotalFileSize='100MB'
              onupdatefiles={handleImageUpload}
              allowMultiple
              maxFiles={5}
              labelIdle='Sürükle Bırak ya da <span class="filepond--label-action">Dosyalardan Seç</span>'
            />
          </ModalBody>
          <ModalFooter bg={borderColor}>
            <Button colorScheme="green" mr={3} onClick={submitForm}>
              Kaydet
            </Button>

            <Button colorScheme="blue" mr={3} onClick={closeModal2}>
              Kapat
            </Button>
            {/* Add other modal actions as needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
