export const turkishCities = ['ADANA', 'ADIYAMAN', 'AFYON', 'AGRI', 'AKSARAY', 'AMASYA', 'ANKARA', 'ANTALYA', 'ARDAHAN', 'ARTVIN', 'AYDIN', 'BALIKESIR', 'BARTIN', 'BATMAN', 'BAYBURT', 'BILECIK', 'BINGOL', 'BITLIS', 'BOLU', 'BURDUR', 'BURSA', 'CANAKKALE', 'CANKIRI', 'CORUM', 'DENIZLI', 'DIYARBAKIR', 'DUZCE', 'EDIRNE', 'ELAZIG', 'ERZINCAN', 'ERZURUM', 'ESKISEHIR', 'GAZIANTEP', 'GIRESUN', 'GUMUSHANE', 'HAKKARI', 'HATAY', 'IGDIR', 'ISPARTA', 'ISTANBUL', 'IZMIR', 'KAHRAMANMARAS', 'KARABUK', 'KARAMAN', 'KARS', 'KASTAMONU', 'KAYSERI', 'KILIS', 'KIRIKKALE', 'KIRKLARELI', 'KIRSEHIR', 'KOCAELI', 'KONYA', 'KUTAHYA', 'MALATYA', 'MANISA', 'MARDIN', 'MERSIN', 'MUGLA', 'MUS', 'NEVSEHIR', 'NIGDE', 'ORDU', 'OSMANIYE', 'RIZE', 'SAKARYA', 'SAMSUN', 'SANLIURFA', 'SIIRT', 'SINOP', 'SIRNAK', 'SIVAS', 'TEKIRDAG', 'TOKAT', 'TRABZON', 'TUNCELI', 'USAK', 'VAN', 'YALOVA', 'YOZGAT', 'ZONGULDAK'];

export const checkEmail = (email) => {
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const formatTurkishDate = (dateString) => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('tr-TR', options);
  
  // Extract day, month, and year separately
  const [day, month, year, time] = formattedDate.split(' ');
  
  // Turkish month names
  const turkishMonths = [
    'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
    'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
  ];

  // Find the index of the month in the Turkish month names array
  const monthIndex = turkishMonths.findIndex(m => m === month);
  
  // Return the formatted date string
  return `${parseInt(day)} ${turkishMonths[monthIndex]} ${year} ${time}`;
};

export function getCurrentDateTime() {
  const now = new Date();
  
  // Get date components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  
  // Get time components
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  
  // Format the date and time
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  
  return formattedDateTime;
}

export function isImageUrl(url) {
  return url.toLowerCase().endsWith('.png') || 
         url.toLowerCase().endsWith('.jpg') || 
         url.toLowerCase().endsWith('.jpeg') || 
         url.toLowerCase().endsWith('.gif') || 
         url.toLowerCase().endsWith('.webp') || 
         url.toLowerCase().endsWith('.bmp') || 
         url.toLowerCase().endsWith('.svg');
}

export  const getFileExtension = (url) => {
  return url.split('.').pop().toLowerCase(); // Get the file extension
};